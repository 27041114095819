<template>
  <nav data-component-name="MenuDesktop">
    <ul class="menu">
      <li
        v-for="item of MENU"
        v-on-click-outside="() => clickOutsideHandler(item)"
        :key="item.path"
      >
        <template v-if="'children' in item">
          <TheHeaderItem
            :focused="item.active"
            @click="toggleMenuItem(item)"
          >
            {{ $t(item.i18nKey) }}
          </TheHeaderItem>

          <TheHeaderItemList
            v-if="Array.isArray(item.children)"
            :visible="item.active"
          >
            <NavItem
              v-for="child of item.children"
              :key="child.path"
              :to="localePathWithSlash(child.path)"
            >
              {{ $t(child.i18nKey) }}
            </NavItem>
          </TheHeaderItemList>

          <TheHeaderItemSubMenu
            v-else
            :visible="item.active"
          >
            <ul class="wrapper">
              <li class="left">
                <ul class="columns">
                  <li
                    v-for="(list, i18nKey) in item.children"
                    :key="i18nKey"
                    class="column"
                  >
                    <ul>
                      <span> {{ $t(i18nKey) }}</span>

                      <li
                        v-for="{ path, i18nKey } of list"
                        :key="path"
                      >
                        <NavItem :to="localePathWithSlash(path)">
                          {{ $t(i18nKey) }}
                        </NavItem>
                      </li>
                    </ul>
                  </li>

                  <MenuResourcesPartial />
                </ul>
              </li>
            </ul>
          </TheHeaderItemSubMenu>
        </template>

        <TheHeaderItem
          v-else
          :to="localePathWithSlash(item.path)"
          component="NuxtLink"
        >
          {{ $t(item.i18nKey) }}
        </TheHeaderItem>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
// components
import NavItem from '~/components/redesign/NavItem.vue';
import TheHeaderItem from '~/components/redesign/TheHeaderItem.vue';
import TheHeaderItemList from '~/components/redesign/TheHeaderItemList.vue';
import TheHeaderItemSubMenu from '~/components/redesign/TheHeaderItemSubMenu.vue';
import MenuResourcesPartial from '~/components/redesign/Menus/MenuResourcesPartial.vue'

// directives
import { vOnClickOutside } from '@vueuse/components';

// utils
import { getNavItems } from './Menu.utils';

// composables
import onRouteChange from '~/composables/onRouteChange';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// types
import type { Item, ItemWithChildren } from './Menu.types';

const localePathWithSlash = useLocalePathWithSlash();

const MENU = reactive<Array<Item | ItemWithChildren>>(getNavItems());

const toggleMenuItem = (item: ItemWithChildren) => {
  MENU.forEach(iterationItem => {
    if (iterationItem.path === item.path) {
      const typedIterationItem = (iterationItem as ItemWithChildren);

      typedIterationItem.active = !typedIterationItem.active;
    } else if ('children' in iterationItem) {
      (iterationItem as ItemWithChildren).active = false;
    }
  });
};

const clickOutsideHandler = (item: Item | ItemWithChildren) => {
  if ('children' in item && item.active) {
    item.active = false;
  }
};

const closeMenu = () => {
  MENU.forEach(iterationItem => {
    if ('children' in iterationItem) {
      (iterationItem as ItemWithChildren).active = false;
    }
  });
};

onRouteChange(closeMenu);
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/mixins/common";
@import "$/functions/token";
@import "$/variables/shadows";

nav[data-component-name="MenuDesktop"] {
  ul.menu {
    @include flex-center-sb;
    color: token('text-primary');

    [data-component-name="TheHeaderItemSubMenu"] {
      ul.wrapper {
        @include fluid-max-width(100%);
        @include flex-start-center;
        gap: 2rem;

        > li {
          width: 50%;
        }

        li.left {
          ul.columns {
            @include flex-start-sb;
            gap: 2rem;

            li.column,
            [data-component-name="MenuResourcesPartial"] {
              width: 33%;

              span {
                display: block;
                padding: 1rem;
                @include subtitle-2;
              }
            }
          }
        }
      }
    }
  }
}
</style>
